import { portalService } from "./service";

export type Step1Request = {
  user_agent: string;
  referrer: string;
  url: string;
};

export type Step1Response = {
  data: {
    session_key: string;
    store_id: string;
    app_data: {
      app_name: string;
      icon_url: string
    }
  };
};

export type Step2Request = {
  name: string;
  email: string;
  phone_number: string;
  session_key: string;
};

export type Step2Response = {
  data: {
    session_key: string;
  };
};

export type Step3Request = {
  session_key: string;
  store_id: string;
  params?: {
    ecpm: number;
    impressions_per_dau: number;
    ad_types: string[];
    t1_country: number;
    t2_country: number;
    t3_country: number;
    avg_daily_dau: number;
  };
};

export type Step3Response = {
  data: {
    session_key: string;
    store_id: string;
    params: {
      ecpm: number;
      impressions_per_dau: number;
      ad_types: string[];
      t1_country: number;
      t2_country: number;
      t3_country: number;
      avg_daily_dau: number;
    };
    prediction: {
      revenue: number;
      impressions: number;
      avg_daily_dau: number;
      impressions_per_dau: number;
      ecpm: number;
      popularity_multiplier: number;
    };
  };
};


export const createSessionStep1 = (
  data: Step1Request
): Promise<Step1Response> => {
  return portalService({
    url: "/integrations/session/step1",
    method: "post",
    data,
  });
};

export const createSessionStep2 = (
  data: Step2Request
): Promise<Step2Response> => {
  return portalService({
    url: "/integrations/session/step2",
    method: "post",
    data,
  });
};

export const createSessionStep3 = (
  data: Step3Request
): Promise<Step3Response> => {
  return portalService({
    url: "/integrations/session/step3",
    method: "post",
    data,
  });
};
