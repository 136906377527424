import React from "react";
import "./App.css";
import NewStepView from './views/newStep/index' 

function App() {
  return (
    <div>
      <NewStepView />
    </div>
  );
}

export default React.memo(App);
