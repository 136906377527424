/**
 * 千分位格式化(小数点保留两位)
 * @param value
 * @param noFixed?
 * @returns
 */
const toThousands = (value: any, noFixed?: boolean): string => {
  let num = (value || 0).toString();
  const decimalIndex = num.indexOf(".");
  let decimalValue = num.slice(decimalIndex, decimalIndex + 3);
  if (noFixed) {
    decimalValue = num.slice(decimalIndex);
    if (decimalValue.length > 5) {
      decimalValue = num.slice(decimalIndex, decimalIndex + 5);
    }
  }

  if (decimalIndex !== -1) {
    num = num.slice(0, decimalIndex);
  }

  let result = "";
  while (num.length > 3) {
    result = "," + num.slice(-3) + result;
    num = num.slice(0, num.length - 3);
  }
  if (num) result = num + result;
  return decimalIndex !== -1 ? result + decimalValue : result;
};

/**
 * 数字保留X位小数，并删除末尾的0
 * @param num 数值 fixed 保留长度
 * @returns string
 */
function numFixed(num: number, fixed?: number) {
  let temp = fixed;
  if (!temp && temp !== 0) {
    temp = 4;
  }
  // delete the 0 at the end of the number
  let value: any = num.toFixed(temp);
  if (Number(value) === parseFloat(value)) {
    value = parseFloat(String(value));
  }
  return value;
}

/**
 * 数字保留X位小数，并删除末尾的0,并且千分位格式化
 * @param num 数值 fixed 保留长度
 * @returns string
 */
function numFixedToThousands(num: number, fixed?: number) {
  return toThousands(numFixed(num, fixed), true);
}

export { toThousands, numFixed, numFixedToThousands };
