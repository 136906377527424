import React, { useState, useRef, useEffect } from "react";
import { Form, Input, Button, Image, Select, Row, Col, Checkbox } from "antd";
import "./index.css";
import "react-phone-number-input/style.css";
import PhoneInput, {
  isPossiblePhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import {
  createSessionStep1,
  createSessionStep2,
  createSessionStep3,
} from "../../api/step";
import { errorCatch } from "../../api/service";
import { numFixedToThousands } from "../../utils";

declare var window: any;

const regExp = /^[+-]?(0|([1-9]\d*))(\.\d+)?$/;
const numberValidate = {
  message: "Please input number.",
  validator: async (rule: any, value: string) => {
    if (regExp.test(value)) {
      return true;
    }
    throw new Error("Please input number.");
  },
};
const adTypesOptions = [
  {
    label: "Banner",
    value: "banner",
  },
  {
    label: "Rewarded Interstitial",
    value: "rewardinter",
  },
  {
    label: "Rewarded Video",
    value: "reward_video",
  },
  {
    label: "Interstitial",
    value: "interstitial",
  },

  {
    label: "App Open Ads",
    value: "openads",
  },
  {
    label: "Native Ads",
    value: "nativeads",
  },
];
function StepView() {
  const Step1FormRef = useRef(null);
  const Step2FormRef = useRef(null);
  const Step3FormRef = useRef(null);
  const [step, setStep] = useState(1);
  const [step1Loading, setStep1Loading] = useState(false);
  const [step2Loading, setStep2Loading] = useState(false);
  const [step3Loading, setStep3Loading] = useState(false);
  const [sessionKey, setSessionKey] = useState("");
  const [storeId, setStoreId] = useState("");
  const [appData, setAppData] = useState({ app_name: "", icon_url: "" });
  const [revenue, setRevenue] = useState<string>("0");
  const [gotRevenue, setGotRevenue] = useState(false);
  const [isNoMobile, setIsNoMobile] = useState(true);
  const [originalRevenue, setOriginalRevenue] = useState(0);

  const submitStep1 = () => {
    if (Step1FormRef) {
      (Step1FormRef as any).current
        .validateFields()
        .then((values: any) => {
          setStep1Loading(true);
          const step1Params = {
            user_agent: navigator.userAgent,
            referrer: window.location.href,
            url: values.link,
          };
          createSessionStep1(step1Params)
            .then(({ data }) => {
              // window.parent.postMessage(
              //   {
              //     height: 600,
              //     step: 1,
              //     request: step1Params,
              //     response: data,
              //   },
              //   "*"
              // );
              setSessionKey(data.session_key);
              setStoreId(data.store_id);
              setAppData(data.app_data);
              setStep(2);
            })
            .catch(errorCatch)
            .finally(() => {
              setStep1Loading(false);
            });
        })
        .catch(() => {});
    }
  };
  const estimateDefaultRevenue = () => {
    const step3Params = {
      session_key: sessionKey,
      store_id: storeId,
    };
    setStep3Loading(true);
    createSessionStep3(step3Params)
      .then(({ data }) => {
        setGotRevenue(true);
        setRevenue(numFixedToThousands(data?.prediction?.revenue || 0, 0));
        setOriginalRevenue(data?.prediction?.revenue || 0);
        if (Step3FormRef && Step3FormRef?.current) {
          (Step3FormRef?.current as any)?.setFieldsValue({
            ad_types: data?.params?.ad_types,
            avg_daily_dau: Number(
              (data?.prediction?.avg_daily_dau || 0).toFixed(0)
            ),
            impressions_per_dau: Number(
              (data?.prediction?.impressions_per_dau || 0).toFixed(2)
            ),
            ecpm: Number((data?.prediction?.ecpm || 0).toFixed(2)),
          });
        }
      })
      .catch(errorCatch)
      .finally(() => {
        setStep3Loading(false);
      });
  };
  const submitStep2 = () => {
    if (Step2FormRef) {
      (Step2FormRef as any).current
        .validateFields()
        .then((values: any) => {
          setStep2Loading(true);
          const step2Params = {
            name: `${values.first_name} ${values.last_name}`,
            email: values.email,
            session_key: sessionKey,
            phone_number:
              "+" +
              (parsePhoneNumber(values.phone_number) as any)
                .countryCallingCode +
              "-" +
              (parsePhoneNumber(values.phone_number) as any).nationalNumber,
            agree_store_personal_info: values.agree_store_personal_info,
            agree_to_communicate: values.agree_to_communicate,
          };
          createSessionStep2(step2Params)
            .then(({ data }) => {
              // Track linkedin conversion event
              if (window.lintrk) {
                window.lintrk("track", { conversion_id: 8422010 });
              }
              const temp = window.matchMedia("(min-width: 768px)").matches;
              // window.parent.postMessage(
              //   {
              //     height: temp ? 600 : 1200,
              //     step: 2,
              //     request: step2Params,
              //     response: data,
              //   },
              //   "*"
              // );
              setGotRevenue(false);
              setRevenue("0");
              setOriginalRevenue(0);
              setStep(3);
              setIsNoMobile(temp);
            })
            .catch(errorCatch)
            .finally(() => {
              setStep2Loading(false);
              estimateDefaultRevenue();
            });
        })
        .catch(() => {});
    }
  };

  const submitStep3 = () => {
    if (Step3FormRef) {
      (Step3FormRef as any).current
        .validateFields()
        .then((values: any) => {
          setStep3Loading(true);
          const step3Params = {
            session_key: sessionKey,
            store_id: storeId,
            params: {
              ad_types: values.ad_types,
              avg_daily_dau: Number(values.avg_daily_dau),
              impressions_per_dau: Number(values.impressions_per_dau),
              ecpm: Number(values.ecpm),
              t1_country: Number(values.country1),
              t2_country: Number(values.country2),
              t3_country: Number(values.country3),
            },
          };
          createSessionStep3(step3Params)
            .then(({ data }) => {
              // window.parent.postMessage(
              //   {
              //     height: isNoMobile ? 600 : 1200,
              //     step: 3,
              //     request: step3Params,
              //     response: data,
              //   },
              //   "*"
              // );
              setGotRevenue(true);
              setRevenue(
                numFixedToThousands(data?.prediction?.revenue || 0, 0)
              );
              setOriginalRevenue(data?.prediction?.revenue || 0);

              // message.success("Estimated revenue found!");
            })
            .catch(errorCatch)
            .finally(() => {
              setStep3Loading(false);
            });
        })
        .catch(() => {});
    }
  };
  const integrateMas = () => {
    window.open(
      "https://mas.yodo1.com/register?utm_source=rp_landing",
      "__blank"
    );
  };

  const bookACall = () => {
    window.open(
      "https://meetings.hubspot.com/contacts-growth-specialist/speak-with-a-yodo1-games-growth-specialist?utm_source=rp_landing",
      "__blank"
    );
  };

  useEffect(() => {
    const handleResize = () => {
      // 处理屏幕宽度变化的逻辑
      setIsNoMobile(window.matchMedia("(min-width: 768px)").matches);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ padding: "0 16px" }}>
        {/* step1 */}
        <>
          {step === 1 && (
            <div className="step-out-box animate__animated animate__zoomIn">
              <div className="step-box">
                <div className="step1-desc">
                  <Image
                    preview={false}
                    src={`${process.env.PUBLIC_URL}/images/Landing.png`}
                    style={{ maxHeight: 300 }}
                  />
                </div>
                <Form
                  onFinish={submitStep1}
                  name="revenue_predictor_v2_1"
                  layout="vertical"
                  ref={Step1FormRef}
                >
                  <Form.Item
                    validateFirst={true}
                    label="Store URL"
                    name="link"
                    tooltip={{
                      title:
                        "Enter your game's Google play store or Apple app store URL to estimate MAS earnings.",
                      color: "blue",
                    }}
                    rules={[
                      {
                        required: true,
                        message: "Please enter your game's store URL.",
                        type: "url",
                      },
                      {
                        message:
                          "Please enter an link from Google Play or Apple App store. You cannot use a studio link.",
                        validator: async (rule, value) => {
                          if (
                            value.startsWith(
                              "https://play.google.com/store/apps/details"
                            ) ||
                            (value.startsWith("https://apps.apple.com/") &&
                              value.includes("/app/"))
                          ) {
                            return true;
                          }
                          throw new Error("Failed to validate store link");
                        },
                      },
                    ]}
                  >
                    <Input
                      style={{ width: "100%" }}
                      placeholder="Enter your game's store link (eg. https://play.google.com/store/app/details?id=...)"
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      type="primary"
                      className="submit-button"
                      loading={step1Loading}
                    >
                      Estimate Potential
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          )}
        </>
        {/* step2 */}
        <>
          {step === 2 && (
            <div
              className="step-out-box animate__animated animate__zoomIn"
              style={{ height: "auto" }}
            >
              <div className="step-box" style={{ height: "auto" }}>
                <div className="app-detail-box">
                  <Image
                    preview={false}
                    width={128}
                    src={appData.icon_url}
                    style={{ minWidth: "128px", borderRadius: "20px" }}
                  />
                  <div
                    className="app-detail-right"
                    style={{ maxWidth: "calc(100% - 140px)" }}
                  >
                    <div
                      className="right-name"
                      style={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {appData.app_name}
                    </div>
                    <div className="right-desc">
                      To unlock your potential earnings with MAS and actionable
                      insights on how you can improve your game please enter
                      your details below
                    </div>
                    <div
                      className="app-detail-right"
                      style={{ filter: "blur(10px)" }}
                    >
                      $999999/month
                    </div>
                  </div>
                </div>
                <Form
                  onFinish={submitStep2}
                  name="revenue_predictor_v2_2"
                  layout="vertical"
                  ref={Step2FormRef}
                >
                  <Row gutter={10}>
                    <Col span={12}>
                      <Form.Item
                        validateFirst={true}
                        label="First name"
                        name="first_name"
                        rules={[
                          {
                            required: true,
                            message: "Please input your first name!",
                          },
                        ]}
                      >
                        <Input placeholder="e.g. John" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        validateFirst={true}
                        label="Last name"
                        name="last_name"
                        rules={[
                          {
                            required: true,
                            message: "Please input your last name!",
                          },
                        ]}
                      >
                        <Input placeholder="e.g. Doe" />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item
                    validateFirst={true}
                    label="Business email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please input your email!",
                      },
                      {
                        message: "Please input correct email!",
                        type: "email",
                      },
                    ]}
                  >
                    <Input placeholder="e.g. johndoe@yodo1.com" />
                  </Form.Item>
                  <Form.Item
                    validateFirst={true}
                    label="Phone number"
                    name="phone_number"
                    rules={[
                      {
                        required: true,
                        message: "Please input your phone number!",
                      },
                      {
                        message: "Please input correct phone number.",
                        validator: async (rule: any, value: string) => {
                          if (isPossiblePhoneNumber(value)) {
                            return true;
                          }
                          throw new Error("Please input correct phone number.");
                        },
                      },
                    ]}
                  >
                    <PhoneInput
                      className="phoneNumber"
                      placeholder="e.g +1 123 456 7890"
                      international
                      initialValueFormat="national"
                      onChange={() => {}}
                    />
                  </Form.Item>

                  <div style={{ color: "#FFF" }} className="step2-checked-box">
                    <div>
                        Yodo1 respects and protects your privacy and uses your information for account administration and providing requested product/services.
                        Sometime we would like to contact you about our product and services. Please select the preferred contact method:{" "}
                    </div>

                    <Form.Item
                      name="agree_to_communicate"
                      valuePropName="checked"
                      initialValue={false}
                      style={{ margin: "10px 0" }}
                    >
                      <Checkbox>
                        I agree to receive other communications from Yodo1.
                      </Checkbox>
                    </Form.Item>
                    <div>
                      In order to provide requested content, we need to store
                      and process personal data. Please check the box below for
                      consent.
                    </div>
                    <Form.Item
                      validateFirst={true}
                      name="agree_store_personal_info"
                      valuePropName="checked"
                      rules={[
                        {
                          validator: (_, value) =>
                            value
                              ? Promise.resolve()
                              : Promise.reject(
                                  new Error(
                                    "Please complete this required field."
                                  )
                                ),
                        },
                      ]}
                      style={{ margin: "10px 0" }}
                    >
                      <Checkbox>
                        I agree to allow Yodo1 to store and process my personal
                        data.
                      </Checkbox>
                    </Form.Item>
                    <div>
                      You can unsubscribe anytime. For more detail on
                      unsubscribing and our commitment to privacy, please review
                      our <a href="https://policy.yodo1.com/website/en/privacy.html">Privacy Policy</a>.
                    </div>
                  </div>
                  <Form.Item style={{ textAlign: "center", marginTop: "30px" }}>
                    <Button
                      htmlType="submit"
                      type="primary"
                      loading={step2Loading}
                    >
                      Unlock potential earnings
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          )}
        </>
        {/* step3 */}
        <>
          {step === 3 && (
            <div
              className="step-out-box animate__animated animate__zoomIn"
              style={{ height: "auto" }}
            >
              <Row>
                <Col span={24}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "100%",
                    }}
                  >
                    <div className="step-box" style={{ height: "100%" }}>
                      <div
                        className="app-detail-box"
                        style={{ alignItems: "center" }}
                      >
                        <Image
                          preview={false}
                          width={128}
                          src={appData.icon_url}
                          style={{ minWidth: "128px", borderRadius: "20px" }}
                        />
                        <div
                          className="app-detail-right"
                          style={{ maxWidth: "calc(100% - 140px)" }}
                        >
                          <div
                            className="right-name"
                            style={{
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {appData.app_name}
                          </div>
                          <div className="right-desc">
                            To view a more accurate estimate of your potential
                            earnings with MAS, please enter your game's metrics
                            and ad types you use below.
                          </div>
                          <div
                            className="app-detail-right"
                            style={gotRevenue ? {} : { filter: "blur(10px)" }}
                          >
                            ${gotRevenue ? revenue : "999999"}/month
                          </div>
                        </div>
                      </div>
                      <Form
                        onFinish={submitStep3}
                        name="revenue_predictor_v2_3"
                        layout="vertical"
                        ref={Step3FormRef}
                        className="step3-form"
                      >
                        <div style={{ display: "flex", gap: "8px" }}>
                          <Form.Item
                            validateFirst={true}
                            label="Ad Types"
                            tooltip={{
                              title:
                                "Select the ad types you use in your game to get a more accurate estimate of your potential earnings with MAS.",
                              color: "blue",
                            }}
                            name="ad_types"
                            rules={[
                              {
                                required: true,
                                message: "Please select at least 1 ad type!",
                              },
                            ]}
                            style={{ flex: 1 }}
                          >
                            <Select
                              mode="multiple"
                              allowClear
                              style={{ width: "100%" }}
                              placeholder="Please select ad types you use"
                              options={adTypesOptions}
                            />
                          </Form.Item>
                          <Form.Item
                            validateFirst={true}
                            label="Avg. Daily DAU"
                            name="avg_daily_dau"
                            tooltip={{
                              title:
                                "Estimated average daily DAU for your game based on it's genre, downloads, and ratings.",
                              color: "blue",
                            }}
                            rules={[
                              {
                                required: true,
                                message: "Please enter your average daily DAU!",
                              },
                              numberValidate,
                            ]}
                            style={{ flex: 1 }}
                          >
                            <Input placeholder="eg. 45000" />
                          </Form.Item>
                        </div>
                        <div style={{ display: "flex", gap: "8px" }}>
                          <Form.Item
                            validateFirst={true}
                            label="Avg. Imp/DAU"
                            name="impressions_per_dau"
                            tooltip={{
                              title:
                                "Estimated impressions per DAU for your game based on it's genre, downloads, and review sentiment.",
                              color: "blue",
                            }}
                            rules={[
                              {
                                required: true,
                                message:
                                  "Please enter your average impressions per DAU!",
                              },
                              numberValidate,
                            ]}
                            style={{ flex: 1 }}
                          >
                            <Input placeholder="e.g. 8.52" />
                          </Form.Item>
                          <Form.Item
                            validateFirst={true}
                            label="Avg. eCPM"
                            tooltip={{
                              title:
                                "Estimated eCPM for your game based on it's genre and estimated player demographics.",
                              color: "blue",
                            }}
                            name="ecpm"
                            rules={[
                              {
                                required: true,
                                message: "Please input your avg. eCPM!",
                              },
                              numberValidate,
                            ]}
                            style={{ flex: 1 }}
                          >
                            <Input placeholder="e.g. 40.1124" />
                          </Form.Item>
                        </div>
                        <div style={{ display: "flex", gap: "8px" }}>
                          <Form.Item
                            validateFirst={true}
                            label="T1 Country Traffic %"
                            name="country1"
                            rules={[
                              {
                                required: true,
                                message:
                                  "Please input traffic from T1 countries!",
                              },
                              numberValidate,
                            ]}
                            style={{ flex: 1 }}
                          >
                            <Input placeholder="e.g. 50" />
                          </Form.Item>
                          <Form.Item
                            validateFirst={true}
                            label="T2 Country Traffic %"
                            name="country2"
                            rules={[
                              {
                                required: true,
                                message:
                                  "Please input traffic from T2 countries!",
                              },
                              numberValidate,
                            ]}
                            style={{ flex: 1 }}
                          >
                            <Input placeholder="e.g. 40" />
                          </Form.Item>
                          <Form.Item
                            validateFirst={true}
                            label="T3 Country %"
                            name="country3"
                            rules={[
                              {
                                required: true,
                                message:
                                  "Please input traffic from T3 countries!",
                              },
                              numberValidate,
                            ]}
                            style={{ flex: 1 }}
                          >
                            <Input placeholder="e.g. 10" />
                          </Form.Item>
                        </div>
                      </Form>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-evenly",
                          gap: 10,
                        }}
                      >
                        <Button
                          type="primary"
                          htmlType="submit"
                          loading={step3Loading}
                          onClick={() => {
                            Step3FormRef?.current &&
                              (Step3FormRef?.current as any)?.submit();
                          }}
                        >
                          Re-estimate Earnings
                        </Button>
                        {originalRevenue > 1000 ? (
                          <Button type="primary" onClick={bookACall}>
                            Book a call
                          </Button>
                        ) : (
                          <Button type="primary" onClick={integrateMas}>
                            Integrate MAS
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </>
      </div>
    </div>
  );
}

export default React.memo(StepView);
