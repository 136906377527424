import axios from "axios";
import { message } from "antd";
import env from '../env'

const portalService = axios.create({
  baseURL: env.apiUrl,
  timeout: 20 * 1000,
});

portalService.interceptors.request.use(
  (config) => {
    // config.headers = getAuthHeader()
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

portalService.interceptors.response.use(
  (response) => {
    const { status } = response;
    switch (status) {
      case 200:
        return response;
      default:
        return Promise.reject(new Error("Network Error"));
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

const errorCatch = (error: {
  response: any;
  message: any;
  toString: () => any;
}) => {
  let msg = "";
  const response = error.response;

  if (response) {
    msg = response?.data?.detail || error?.message || error?.toString();
  } else {
    // TODO: optimize this message
    msg = "Network Error";
  }
  message.error(msg);
};

export { portalService, errorCatch };
